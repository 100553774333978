import { useContext, useEffect, useState } from "react";

import { PlatformContext } from "../context/platformContext";
export const useSesion = () => {
  const { platformState, dispatch }: any = useContext(PlatformContext);
  const [users]: any = useState(null);

  const data = async () => {
    if (!platformState.token || !platformState.session) return verifySesion();
  };
  useEffect(() => {
    data();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return { users };
};

const verifySesion = () => {
  return window.location.replace("/");
};
