import CardBoard from "./card";


import { useRolesForUser } from "../../hooks/rolesForUserHook";
import { Col, Input, Row } from "antd";
import Spinner from "../../components/spinner";
import Paginator from "../../components/paginator";
import { useContext, useEffect, useState } from "react";
import { useDebounce } from "../../hooks/debounces";
import { useMyBoards } from "../../hooks/myboardsHook";

const ListBoards = () => {
  const { boards }: any = useMyBoards();
  const { rolesUser } = useRolesForUser();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, { wait: 500 });

  const buscar = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    if (debouncedSearchTerm) {
      // Aquí realizas la búsqueda con debouncedSearchTerm
      console.log("Buscando:", debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);
  return (
    <>
      <Row justify="end">
        <Col>
          <Input type="text" placeholder="Buscar..." onChange={buscar} />
        </Col>

        <Col style={{ marginInline: 15 }}>
          <Paginator />
        </Col>
      </Row>
      <Row
        gutter={{ xs: 6, sm: 12, md: 24, lg: 32 }}
        style={{
          justifyContent: rolesUser?.length > 2 ? "center" : "flex-start",
        }}
      >
        {!boards ? (
          <Spinner />
        ) : (
          <>
            {boards?.map((e: any) => {
              return (
                <Col
                  xs={24}
                  md={12}
                  lg={6}
                  key={e.uuid}
                  style={{ marginTop: "1rem" }}
                >
                  <CardBoard data={e} role={rolesUser?.length} />
                </Col>
              );
            })}
          </>
        )}
      </Row>
    </>
  );
};

export default ListBoards;

//$2b$10$RzUDaeJZK6Z4HrtLj0oNguQ3aFqIlmu9H0OeBTmCvjfZz9Fq33OG2
