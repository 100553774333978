import { useContext, useEffect, useState } from "react"
import { PlatformContext } from "../context/platformContext"
import { getSurveyAll } from "../services/survey"

export const useSurvey = () => {
    const [surveys, setBoards]: any = useState([])
    const { platformState }: any = useContext(PlatformContext)
    async function getData(){
        const data = await getSurveyAll(platformState.token,platformState.session)
        console.log(data)
        return setBoards(data)
    } 

    useEffect(() =>{
        
       getData()
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[platformState.openModal, platformState.test, platformState.skip, platformState.search] )

    return  { surveys }
}
