import { api } from "../environment";

export const updateNoti =async (data: any, id: string, token: string, sessionTem : any) => {

    if (!sessionTem) return
   let {session} = sessionTem
    let options = {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
        'session': `${session}`
      },
      body: JSON.stringify(data)
    }
  
    try {

      const login = await fetch(api + "notifications/" + id, options
      );
      const data= await login.json();
      return data;
    } catch (e) {
      return e;
    }
  }