
const horaToday = (idioma: any) : string =>{
    let datos = new Date()
    let dia = datos.getDate()
    let mes = datos.getUTCMonth()+1


    let ano = datos.getFullYear()
    let hora = datos.getHours()
    let min = datos.getMinutes()

    let res = formatAMPM(datos)+" "+dia+ " de "+ mess(mes, idioma)+" "+ ano

    return res
}

const mess = (num: number, idioma: any) => {
   
    const meses : any= [
        { id: 1, es:"enero" , en: "january" },
        { id: 2, es:"febrero" , en: "february" },
        { id: 3, es:"marzo" , en: "march" },
        { id: 4, es:"abril" , en: "april" },
        { id: 5, es:"mayo" , en: "may" },
        { id: 6, es:"junio" , en: "june" },
        { id: 7, es:"julio" , en: "july" },
        { id: 8, es:"agosto" , en: "agosto" },
        { id: 9, es:"septiembre" , en: "september" },
        { id: 10, es:"ocutubre" , en: "octuber" },
        { id: 11, es:"noviembre" , en: "november" },
        { id: 12, es:"diciembre" , en: "december" },
    ]
    for(let i in meses){
        if(meses[i].id === num ){
            return meses[i][idioma]
        }
    }

}

function formatAMPM(date: any) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes+ ampm;
    return strTime;
  }
export {
    horaToday
}