import { useContext, useEffect, useState } from "react";
import { PlatformContext } from "../context/platformContext";
import { getLinks } from "../services/linksServices";
import { types } from "../types/types";





export const useLinks = () => {
    const { platformState, dispatch }: any = useContext(PlatformContext)
    const [links, setBoards]: any = useState([])

   const  data = async(id: any) => {

        const data = await getLinks(id, platformState.skip, platformState.limit, platformState.search,platformState.token, platformState.session)
      
      
        if(data.message === 'Unauthorized'){
            dispatch({
                type: types.token,
                payload:null
            })
            dispatch({
                type: types.session,
                payload:null
            })
            return  setBoards( [])
          }
          return setBoards(data || [])
    }
    useEffect(() =>{
        data(platformState.session.company[0])   
       
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[platformState.test, platformState.skip, platformState.search] )

    return  { links }
}

