
import { Route, Routes } from "react-router-dom";
import ShinyView from "./shinyView";
import ListDataVikua from "./listDataVikua";

const DataVikua = () => {
    return(<>
        
        <Routes >
               
               <Route path="view" element={<ShinyView />} />
               <Route path="/" element={<ListDataVikua />} />
           </Routes>
    </>)
}

export default DataVikua;