
import {  BrowserRouter, HashRouter ,Routes, Route } from "react-router-dom";

import DashboardRouter from './DashboardRouter'
import AuthRouter from "./AuthRouter";
import { PlatformProvider } from "../context/platformContext";



export const VikuaPlatformApp = () => {
    
  
    return (
        <PlatformProvider>
            <HashRouter >
                <Routes>


                   
                    <Route path="/" element={<AuthRouter />} />
  
                    <Route path="dashboard/*" element={ <DashboardRouter />} />
                </Routes>
            </HashRouter>
        </PlatformProvider>

    )
}