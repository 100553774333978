/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import "../../styles/login.css";
import { PlatformContext } from "../../context/platformContext";
import { loadLanguaje } from "../../hooks/loadLanguje";
import { useNavigate } from "react-router-dom";
import { getMain, login } from "../../hooks/login";
import { types } from "../../types/types";
import { confirmPassword, error } from "../../services/alertsServices";
import { Button, Form, Input } from "antd";
import { Typography } from "antd";
import { PasswordProps } from "antd/es/input";


type FieldType = {
  email?: string;
  password?: PasswordProps;
};

const { Title } = Typography;


const Login = ({setEmail, setPassword, setVeri}: any) => {
  let history: any = useNavigate();

  const { platformState, dispatch }: any = useContext(PlatformContext);
  const [form] = Form.useForm();

  useEffect(() => {
    loadLanguaje("es", {}, dispatch);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendLogin = async () => {
    //const ip = await getIp()
    let {email, password} = form.getFieldsValue()
    let env = {
      email: email,
      password: password
      
    };
    setEmail(email)
    setPassword(password)
    
    const send: any = await login(env);
    if (send.message) {
      if(send.message === "Please check your mail"){
        return setVeri(true)
      }
      return error(send.message);
    }
    await dispatch({
      type: types.token,
      payload:send.token
    })
    let save = {
      idU: send.uuid,
      ...send,
    };
    let main = await getMain(send.uuid)
    await dispatch({
      type: types.dataProfile,
      payload: save
    })
    await dispatch({
      type: types.session,
      payload:save,
    });
    await dispatch({
      type: types.main,
      payload:main,
    });
   return history(`dashboard/${platformState.language.sidebarMisTableros}`);
 
  };

  return (
    <>
      <Form
        id="formulario"
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={sendLogin}
        style={{  textAlign:"center" }}
        labelAlign={'left'}
      >
        <Title level={4} >
          {platformState.language.loginTitleForm}
        </Title>
        <Form.Item<FieldType>
          label={platformState.language.loginEmail}
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input your email!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label={platformState.language.loginPassword}
          name="password"
          rules={[
            {
              required: true,
              
              message: "Please input your email!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 24 }}>
          <Button type="primary" htmlType="submit">
            {platformState.language.loginButtonSendLongin}
          </Button>
        </Form.Item>
        <a className="recuperatePassword" onClick={() => confirmPassword()}>
            {platformState.language.loginRecuperatePassword}
          </a>
      </Form>


    </>
  );
};

export default Login;
