
import {api} from '../environment';

async function  getSurvey( token: string, sessionTem : any) {
   
    if (!sessionTem) return
    let { idU, session } = sessionTem
    let options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
        'session': `${session}`
      }
    }
  
    try {
      
      const login = await fetch(api + "survey/"+idU, options
      );
      const data = await login.json();
      return data;
    } catch (e) {
      return e;
    }
}
async function  getSurveyAll( token: string, sessionTem : any) {
   
  if (!sessionTem) return
  let {  session } = sessionTem
  let options = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    }
  }

  try {
    
    const login = await fetch(api + "survey/all", options
    );
    const data = await login.json();
    return data;
  } catch (e) {
    return e;
  }
}
async function resposeSurvey(data:any, id: string, token: string, sessionTem : any) {

    if (!sessionTem) return
    let { session } = sessionTem
    let options = {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
        'session': `${session}`
      },
      body: JSON.stringify(data)
    }
  
    try {
  
    
      const login = await fetch(api + "survey/" + id, options
      );
      const data= await login.json();
      return data;
    } catch (e) {
      return e;
    }
}

export {
    getSurvey,
    resposeSurvey,
    getSurveyAll
}