import { useContext, useEffect } from "react";
import "../../styles/login.css";
import { PlatformContext } from "../../context/platformContext";
import { loadLanguaje } from "../../hooks/loadLanguje";
import { useNavigate } from "react-router-dom";
import { getMain, verificar } from "../../hooks/login";
import { types } from "../../types/types";
import {  error } from "../../services/alertsServices";
import { Button, Form, Input } from "antd";
import { Typography } from "antd";
import { PasswordProps } from "antd/es/input";
import { getIp } from "../../services/ipservices";

type FieldType = {
  email?: string;
  password?: PasswordProps;
  code?:number
};

const { Title } = Typography;


const Register  = ({email, password}: any) => {
    let history: any = useNavigate();

    const { platformState, dispatch }: any = useContext(PlatformContext);
    const [form] = Form.useForm();
  
    useEffect(() => {
      loadLanguaje("es", {}, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const sendLogin = async () => {
    
      let {code} = form.getFieldsValue()
      let env = {
        email: email,
        password: password,
        code: parseInt(code)
      };
      const send: any = await verificar(env);
      if (send.message) {
        return error(send.message);
      }
      await dispatch({
        type: types.token,
        payload:send.token
      })
      let save = {
        idU: send.uuid,
        ...send,
      };
      let main = await getMain(send.uuid)
      await dispatch({
        type: types.dataProfile,
        payload: save
      })
      await dispatch({
        type: types.session,
        payload:save,
      });
      await dispatch({
        type: types.main,
        payload:main,
      });
     return history(`dashboard/${platformState.language.sidebarMisTableros}`);
   
    };
  
    return (
      <>
        <Form
          id="formulario"
          layout="vertical"
          form={form}
          name="control-hooks"
          onFinish={sendLogin}
          style={{  textAlign:"center" }}
          labelAlign={'left'}
        >
          <Title level={4} >
            {platformState.language.loginTitleForm}
          </Title>
          <Form.Item<FieldType>
            label={platformState.language.loginVerificateForm}
            name="code"
            rules={[
              {
                required: true,
           
              },
            ]}
          >
            <Input  />
          </Form.Item>
  
  
          <Form.Item wrapperCol={{ span: 24 }}>
            <Button type="primary" htmlType="submit">
              {platformState.language.loginButtonSendLongin}
            </Button>
          </Form.Item>
          

        </Form>
  
  
      </>
    );
}

export default Register;