export const types: any = {
    // General config 

    languaje: "[Platform] load Languaje",
    ruta: "[Platform] load ruta",
    urlShinny: "[Platform] load shinny",
    token:"[Platform] load token",
    dataProfile: "[Platform] load profile",
    test: "[Platform] update view",
    dataUpdate: "[Platform] update data",
    title: "[Platform] update title",
    skip: "[Platform] update skip",
    limit: "[Platform] update limit",
    search: "[Platform] update search",
    company: "[Platform] update company",
    botonTitle: "[Platform] update Boton",
    openModal: "[Platform] open modal",
    uuidBoard: "[Platform] caragr data",
    session: "[Platform] cargar sesion",
    main: "[Platform] menu",
    notifications: "[Platform] noti"

}