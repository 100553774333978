/* eslint-disable jsx-a11y/alt-text */
import { useContext, useEffect } from "react";
import "../../styles/users.css";
import { PlatformContext } from "../../context/platformContext";
import { types } from "../../types/types";
import { confirm } from "../../services/alertsServices";
import { useRolesForUser } from "../../hooks/rolesForUserHook";
import { useCompaniesAll } from "../../hooks/companiesHook";
import Spinner from "../../components/spinner";
import { TableCustom } from "../../components/table";
import { Button, Flex, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const Tables = () => {
  const { platformState, dispatch }: any = useContext(PlatformContext);
  const { companies }: any = useCompaniesAll();

  const columns = [
    {
      title: platformState.language.tableName,
      dataIndex: "name",
      key: "name",
    },
    {
      title: platformState.language.modalCompaniesProject,
      dataIndex: "fatherName",
      key: "fatherName",
    },
    {
      title: platformState.language.insingDescription,
      dataIndex: "description",
      key: "description",
    },

    {
      title: platformState.language.tableAcciones,
      dataIndex: "uuid",
      key: "uuid",
      render: (uuid: string) => {
        return (
          <Flex gap="small" wrap>
            <Button
              onClick={() => {
                uploadData(uuid);
              }}
            >
              <EditOutlined />
            </Button>
            
            <Button
              onClick={() => {
                borrar(uuid);
              }}
            >
              <DeleteOutlined />
            </Button>
          </Flex>
        );
      },
    },
  ];

  useEffect(() => {}, [platformState.test]);

  function uploadData(item: any) {
    const data = companies.find((m: any) => m.uuid === item)

    dispatch({
      type: types.botonTitle,
      payload: platformState.language.userFormBotonEditar,
    });
    dispatch({
      type: types.title,
      payload: platformState.language.modalCompaniesTitleEdit,
    });
    dispatch({
      type: types.openModal,
      payload: true
    })
    return dispatch({
      type: types.dataUpdate,
      payload: data,
    });
  }
  const borrar = async (id: string) => {
    return confirm(id, dispatch, "companies",platformState.token, platformState.session);
  };

  return (
    <>
      {!companies ? (
        <Spinner />
      ) : (
        <TableCustom columns={columns} data={companies} />
      )}
    </>
  );
};

export default Tables;
