import { useContext, useEffect, useState } from "react";
import { getUsers } from "../services/usersServices";
import { PlatformContext } from "../context/platformContext";
import { types } from "../types/types";





export const useUsers = () => {
    const { platformState, dispatch }: any = useContext(PlatformContext)
    const [users, setBoards]: any = useState(null)

   const  data = async(id: any) => {

        const data = await getUsers(id, platformState.skip, platformState.limit, platformState.search,platformState.token, platformState.session)
       
       console.log(">>>",data)
        if(data.message === 'Unauthorized'){
            dispatch({
                type: types.token,
                payload:null
            })
            dispatch({
                type: types.session,
                payload:null
            })
            return  
          }
        return setBoards(data || [])
    }
    useEffect(() =>{

        
            
                data(platformState.session.idU)   
      
       
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[platformState.opeModal, platformState.skip, platformState.search, platformState.test] )

    return  { users }
}

