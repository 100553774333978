import { useContext, useEffect, useState } from "react"
import { PlatformContext } from "../context/platformContext"
import { getData } from "../services/ticketsServices"
import { types } from "../types/types"

export const useTickets = () => {
    const [tickest, setBoards]: any = useState(null)
    const { platformState, dispatch }: any = useContext(PlatformContext)
    async function data() {
        setBoards([])
        if(platformState.session){
            const dato: any = await getData(platformState.limit, platformState.skip, platformState.search,platformState.token, platformState.session)
          
            if(!dato || dato.message === 'Unauthorized'){
                dispatch({
                    type: types.token,
                    payload:null
                })
                dispatch({
                    type: types.session,
                    payload:null
                })
              return
            }        
            return setBoards(dato || [])
        }
      
   
        return setBoards([])
    }
    useEffect(() =>{
        
        data()
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[  platformState.skip, platformState.search] )

    return  { tickest }
}