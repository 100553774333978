import { useContext, useEffect } from "react";
import Tables from "./table";
import { PlatformContext } from "../../context/platformContext";
import Paginator from "../../components/paginator";
import { types } from "../../types/types";
import { Col, Input, Row } from "antd";

const Users = () => {
  const { dispatch }: any = useContext(PlatformContext);

  useEffect(() => {}, [dispatch]);

  function buscar(value: string) {
    dispatch({
      type: types.search,
      payload: value,
    });
  }
  return (
    <>
      <Row justify="end">
        <Col>
          <Input
            type="text"
            placeholder="Buscar..."
            onChange={(e) => buscar(e.target.value)}
          />
        </Col>

        <Col style={{ marginInline: 15 }}>
          <Paginator />
        </Col>
      </Row>

      <Tables />
    </>
  );
};

export default Users;
