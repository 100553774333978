import { useContext, useState } from "react";

import { LineChart } from "@mui/x-charts";
import {
  Card,
  Col,
  DatePicker,
  DatePickerProps,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { useCompanies } from "../../hooks/companiesHook";
import { PlatformContext } from "../../context/platformContext";
import {
  getLogSessions,
  getLogSessionsInterval,
} from "../../services/reporteService.";

import {
 
  BarChart,
  Bar,
  XAxis,
  YAxis,

  Cell,
} from "recharts";
import { Surveys } from "./surveys";

const { Text } = Typography;

export const Reports = () => {
  const { platformState }: any = useContext(PlatformContext);
  const [x, setX]: any = useState([]);
  const [y, setY]: any = useState([]);
  const  companies  = useCompanies().companies;
  const [horaOne, setHoraOne] = useState(0);
  const [horaTwo, setHoraTwo] = useState(0);
  const [company, setCompany] = useState<any>(null);
  const [width] = useState(window.innerWidth);
  const [dataset, setDataset]: any = useState([]);
  const handleChange = async (value: any) => {
    if (value !== "none" && value && horaOne > 0 && horaTwo > 0) {
      setCompany(value);
      setX(null);
      setDataset(null);
      getDataGraficabarras(value);
      return getDataGraficaLinea(value);
    }

    setX([]);
    setY([]);
    setDataset([]);
    return;
  };
  const getDataGraficaLinea = async (id = null) => {
    if (
      horaOne <= horaTwo &&
      horaTwo > 0 &&
      ((company && company !== "none") || (id && id !== "none"))
    ) {
      const data = await getLogSessionsInterval(
        id || company,
        horaOne,
        horaTwo,
        platformState.token,
        platformState.session
      );

      setX(data.x);
      setY(data.y);
      return;
    }
  };
  const getDataGraficabarras = async (id = null) => {
    if (
      horaOne <= horaTwo &&
      horaTwo > 0 &&
      ((company && company !== "none") || (id && id !== "none"))
    ) {
      const data = await getLogSessions(
        id || company,
        horaOne,
        horaTwo,
        platformState.token,
        platformState.session
      );
      
      return setDataset(data.arrayUsers.sort((a: any, b: any) => {
        return a.number - b.number;
    }));
    }
  };
  const onChange: DatePickerProps["onChange"] = (
    date: any,
    dateString: any
  ) => {
    let hora = new Date(dateString).getTime();
    setHoraOne(hora / 1000);
    return handleChange(company);
  };
  const onChangeTwo: DatePickerProps["onChange"] = (
    date: any,
    dateString: any
  ) => {
    let hora = new Date(dateString).getTime();
    setHoraTwo(hora / 1000);
    return handleChange(company);
  };

  return (
    <>
      <Row justify={"end"}>
        <Col style={{ marginInline: 10 }}>
          <Text>Desde : </Text>
          <DatePicker onChange={onChange} />
        </Col>
        <Col style={{ marginInline: 10 }}>
          <Text>Hasta : </Text>
          <DatePicker onChange={onChangeTwo} />
        </Col>
        <Col>
          <Text>Empresa : </Text>

          <Select
            defaultValue="none"
            onChange={handleChange}
            options={[
              {
                value: "none",
                label: platformState.language.companyTypeThree,
              },
              ...companies.map((e: any) => {
                return {
                  value: e.uuid,
                  label: e.name,
                };
              }),
            ]}
          />
        </Col>
      </Row>
      <Row justify={"space-evenly"}>
        <Col
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            alignContent: "center",
          }}
        >
          <Card style={{ minHeight: 400, minWidth: 400 }}>
            {x && y ? (
              <LineChart
                xAxis={[{ scaleType: "point", data: y }]}
                series={[
                  {
                    label: "Sesiones de empresas por dias",
                    data: x,
                  },
                ]}
                width={y.length < 13 ? width * 0.35 : width * 0.8}
                height={400}
              />
            ) : (
              <Spin style={{ marginInline: "40%" }} />
            )}{" "}
          </Card>
        </Col>
        <Col>
          <Card style={{ minHeight: 400, minWidth: 400 }}>
            {dataset ? (
              <><p>Sesones por usuario</p>
              <BarChart
                data={dataset}
                layout="vertical"
                width={y.length < 13 ? width * 0.35 : width * 0.8}
                height={350}
                
              >
                
                <XAxis   />
                <YAxis
                  type="category"
                  dataKey="email"
           
                  style={{ fontSize: 7 }}
                />
                <Bar
                  dataKey="number"
                  barSize={30}
                  fill="#8884d8"
                  label={{ position: "right" }}
                 
                >
              
                  {dataset.map((entry: any, index: any) => (
                    <Cell key={`cell-${index}`} color="black"/>
                  ))}{" "}
                </Bar>
              </BarChart></>
            ) : (
              <Spin style={{ marginInline: "40%" }} />
            )}
          </Card>
        </Col>
      </Row>
      {platformState.session.email === "admin@vikua.com" && (<Surveys />)}
      
    </>
  );
};
