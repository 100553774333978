import { useState, useEffect, useContext } from "react";

import { PlatformContext } from "../../context/platformContext";
import { types } from "../../types/types";

import "../../styles/modal.css";

import { DataLabels } from "../../interfaces/dataLabels";
import DataLabel from "../../components/datalabels/dataLabels";
import { Button, Drawer, Form, Input, Select } from "antd";

import { getProfile } from "../../services/usersServices";
import TextArea from "antd/es/input/TextArea";
import { updateData } from "../../services/ticketsServices";

const FormsTickest = ({ roles }: any) => {
  const { platformState, dispatch }: any = useContext(PlatformContext);

  const [test, setTest] = useState(true);
  const [dataLabels, setDataLabels] = useState<Array<DataLabels>>([]);
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [usuario, setUsuario]: any = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
   
    if (platformState.dataUpdate) {
      console.log(platformState.dataUpdate)
      form.setFieldValue("name", platformState.dataUpdate.title);
      form.setFieldValue("description", platformState.dataUpdate.description);
      form.setFieldValue("status", platformState.dataUpdate.status);
      getProfil(platformState.dataUpdate.user);

      setTest(false);
    } else {
      limpiar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformState.dataUpdate, test]);

  const update = async () => {
    let { status } = form.getFieldsValue();
    let env: any = {
      status: status,
    };

    await updateData(env, platformState.dataUpdate.uuid,platformState.token, platformState.session);
    limpiar();
    return dispatch({
      type: types.openModal,
      payload: false,
    });
  };

  function limpiar() {
    form.resetFields();

    dispatch({
      type: types.dataUpdate,
      payload: null,
    });
  }

  const action = async () => {
    if (!platformState.dataUpdate) {
      return;
    }
    return await update();
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  const getProfil = async (id: any) => {
    const prof = await getProfile(id,platformState.token, platformState.session);
    console.log(prof);
    setUsuario(prof);
  };
  return (
    <>{platformState.dataUpdate 
        ?<>
        {usuario && (
        <>
          <p>
            <strong>Usuario :</strong> {usuario.name} {usuario.lastname}
          </p>
          <p>
            <strong>Correo :</strong> {usuario.email}
          </p>
          <p>
            <strong>Telefono :</strong> {usuario.phone}
          </p>
          <Form
            name="wrap"
            labelCol={{ flex: "110px" }}
            labelAlign="left"
            labelWrap
            wrapperCol={{ flex: 1 }}
            colon={false}
            form={form}
            onFinish={action}
            style={{ textAlign: "center" }}
          >
            <Form.Item
              label={platformState.language.dataVikuaTitle}
              name="name"
              rules={[{ required: true }]}
            >
              <Input type="text" disabled={true} />
            </Form.Item>
            <Form.Item
              label={platformState.language.modalBoardasDescription}
              name="description"
              rules={[{ required: true }]}
            >
              <TextArea disabled={true} />
            </Form.Item>

            <Form.Item
              label={platformState.language.tableEstado}
              name="status"
              rules={[{ required: true }]}
              
            >
              <Select disabled={roles > 2 ? false: true}>
                <Select.Option value="solicitado">Open</Select.Option>
                <Select.Option value="en progreso">Progress</Select.Option>
                <Select.Option value="cerrada">Closed</Select.Option>
                <Select.Option value="rechazada">Reject</Select.Option>
              </Select>
            </Form.Item>

            {/** 
        <Form.Item label=" " style={{ textAlign: "end" }}>
          <Button
            type="primary"
            htmlType="button"
            onClick={showChildrenDrawer}
          >
            campos adicionales
          </Button>
        </Form.Item>*/}
        {roles > 2 && (
             <Form.Item label=" " style={{ textAlign: "end" }}>
             <Button type="primary" htmlType="submit">
               {platformState.botonModalForm}
             </Button>
           </Form.Item>
        )}

         
          </Form>
        </>
      )}

      <Drawer
        title="Campos adicionales"
        width={420}
        closable={true}
        onClose={onChildrenDrawerClose}
        open={childrenDrawer}
      >
        <DataLabel
          platformState={platformState}
          setDataLabels={setDataLabels}
          dataLabels={dataLabels}
        />
      </Drawer>
        </>
        :<>
          <h6>Eres Administrador no puedes crear tickest
          </h6>
        </>

    }
      
    </>
  );
};

export default FormsTickest;
