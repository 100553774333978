import { Collapse, Form, Input, Select } from "antd";
import {  useEffect} from "react";
import { DeleteOutlined } from "@ant-design/icons";


const DataLabelsAsig = ({ dataLabels, setDataLabels, company, cerrar }: any) => {
  const [form] = Form.useForm();
  let array = dataLabels.slice();

  useEffect(() => {
   
    for (let i in dataLabels) {
      if (!array[i].uuidEmpresa) array[i].uuidEmpresa = company;
      if (array[i].type === "readonlyInput") {
        array[i].valueAsig = array[i].value;
        setDataLabels(array);
      }
    }
  }, []);
  const borrar = (e: any) => {

    const index = dataLabels.indexOf(e);

    if (index > -1) {
      array.splice(index, 1);
      setDataLabels(array);
      if (array.length <= 0) {
        
        return cerrar()
    }
  };
  }
  const onChangeValue = (label: string, value: string, uuid: string): void => {
    // Debounce timer variable
    let timeoutId: any = null;
  
    const debouncedOnChange = (label: string, value: string, uuid: string) => {
      // Clear any previous timer to prevent redundant execution
      let delayInMilliseconds = value ? 500 : 0;
      clearTimeout(timeoutId);
  
      // Set a new timer to execute the original function after a delay
      timeoutId = setTimeout(() => {
        const foundItem = array
          .map((dataLabel: any) => dataLabel.uuid)
          .indexOf(uuid);
  
        if (foundItem > -1) {
          const index = array[foundItem].items
            .map((e: any) => e.label)
            .indexOf(label);
  
          if (index > -1) {
            array[foundItem].uuidEmpresa = company;
            array[foundItem].items[index].valueAsig = value;
            // Update your state or data here (assuming setDataLabels exists)
            // setDataLabels(array);
          }
        }
      }, delayInMilliseconds); // Replace with your desired delay
    };
  
    // Call the debounced function with the original arguments
    debouncedOnChange(label, value, uuid);
  };
  return <Collapse accordion items={dataLabels.map((e: any) => {
    return {
      key: Math.random(),
      label: e.name,
      extra: (
        <DeleteOutlined
          onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
       
            borrar(e)
          }}
        />
      ),
      children: (
        <Form form={form}>
          {e.items?.map((l: any) => {
            switch (l!.type) {
              case "input":
                return (
                  <Form.Item label={l.label} name={l.label}>
                    <Input
                      onChange={(a) => {
                        onChangeValue(l.label, a.target.value, e.uuid);
                      }}
                      value={`${form.setFieldValue(l.label, l.valueAsig)}`}
                    />
                  </Form.Item>
                );
              case "select":
                return (
                  <Form.Item label="Select" name={l.label}>
                    <Select
                      onChange={(a:any) => {
                        onChangeValue(l.label, form.getFieldValue(l.label), e.uuid);
                      }}
                      value={form.setFieldValue(l.label, l.valueAsig)}
                    >
                      {l.values?.map((m: any) => {
                        return (
                          <Select.Option value={m}>{m}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                );
              default:
                return <>No hay campos adicionales </>;
            }
          })}
        </Form>
      ),
    };
  })} />;
};

export default DataLabelsAsig;
