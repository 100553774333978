import {
    iconMisTableros,
    iconTablerosVikua,
    iconInsights,
    iconDataPlayer,
    iconMiData,
    iconDataVikua,
    iconUsers,
    iconEmpresas,
    iconAdministrator,
    iconExit,
    iconBotton,
  } from "../../assets/index";

  import "../../styles/iconCustom.css"
  export const IconExit= () => {
    return <>
        <img className="iconcustom anticon anticon-container ant-menu-item-icon" src={iconExit} />
    </>
}
  export const IconAdministrato= () => {
    return <>
        <img className="iconcustom anticon anticon-container ant-menu-item-icon" src={iconAdministrator} />
    </>
}
  export const IconEmpresas= () => {
    return <>
        <img className="iconcustom anticon anticon-container ant-menu-item-icon" src={iconEmpresas} />
    </>
}
  export const IconUsers= () => {
    return <>
        <img className="iconcustom anticon anticon-container ant-menu-item-icon" src={iconUsers} />
    </>
}
  export const IconDataVikua= () => {
    return <>
        <img className="iconcustom anticon anticon-container ant-menu-item-icon" src={iconDataVikua} />
    </>
}
  export const IconMiData= () => {
    return <>
        <img className="iconcustom anticon anticon-container ant-menu-item-icon" src={iconMiData} />
    </>
}
  export const IconDataPlayer= () => {
    return <>
        <img className="iconcustom anticon anticon-container ant-menu-item-icon" src={iconDataPlayer} />
    </>
}
  export const IconMistableros = () => {
    return <>
        <img className="iconcustom anticon anticon-container ant-menu-item-icon" src={iconMisTableros} />
    </>
}

export const IconTablerosVikua = () => {
    return <>
        <img className="iconcustom anticon anticon-container ant-menu-item-icon" src={iconTablerosVikua} />
    </>
}

export const IconInsights = () => {
    return <>
        <img className="iconcustom anticon anticon-container ant-menu-item-icon" src={ iconInsights} />
    </>
}

