import { useContext, useEffect, useState } from "react";
import { getRoles } from "../services/rolesServices";
import { PlatformContext } from "../context/platformContext";





export const useRoles = () => {
    const [roles, setBoards]: any = useState([])
    const { platformState }: any = useContext(PlatformContext);
    async function data() {

        const data = await getRoles(platformState.token, platformState.session)
        if(data?.statusCode === 401){
          return  setBoards([])
        }
   
        return setBoards(data)
    }
    useEffect(() =>{
        
        data()
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[] )

    return  { roles }
}

