import { api } from "../environment";

export const getLogSessions = async (id: string,desde: number, hasta:number, token: string, sessionTem : any) => {

    if (!sessionTem) return
    let { session } = sessionTem
    let options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
        'session': `${session}`
      }
    }
  
    try {

      const login = await fetch(`${api}reports/login/${id}?desde=${desde}&hasta=${hasta}`, options);
      const data = await login.json();
      return data;
    } catch (e) {
      return e;
    }
  }
  
  export const getLogSessionsInterval = async (id: string,desde: number, hasta:number, token: string, sessionTem : any) => {

    if (!sessionTem) return
    let { session } = sessionTem
    let options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
        'session': `${session}`
      }
    }
  
    try {

      const login = await fetch(`${api}reports/loginInterval/${id}?desde=${desde}&hasta=${hasta}`, options);
      const data = await login.json();
      return data;
    } catch (e) {
      return e;
    }
  }
  
    
  export const getGrapichSurvey = async (id: string, token: string, sessionTem : any) => {

    if (!sessionTem) return
    let { session } = sessionTem
    let options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
        'session': `${session}`
      }
    }
  
    try {

      const login = await fetch(`${api}reports/survey/${id}`, options);
      const data = await login.json();
      return data;
    } catch (e) {
      return e;
    }
  }

  export const getGrapichSurveyDetail = async (id: string, token: string, sessionTem : any) => {

    if (!sessionTem) return
    let { session } = sessionTem
    let options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
        'session': `${session}`
      }
    }
  
    try {

      const login = await fetch(`${api}reports/surveyDetail/${id}`, options);
      const data = await login.json();
      return data;
    } catch (e) {
      return e;
    }
  }
 