import { useContext, useEffect, useState } from "react";
import { PlatformContext } from "../../context/platformContext";
import { types } from "../../types/types";
import { Card, Modal } from "antd";
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getCompanie, getSubCompanies } from "../../services/companiesServices";
import { confirm, selectCompany, success } from "../../services/alertsServices";
import "../../styles/card.css";
import { Input } from "antd";
import { postData } from "../../services/ticketsServices";

const { TextArea } = Input;

const { Meta } = Card;

const CardBoard = ({ data, role }: any) => {
  let navigate: any = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState("");

  const { platformState, dispatch }: any = useContext(PlatformContext);
  const [actions, setActions] = useState<any>([
    <p
      onClick={() => {
        return showModal();
      }}
    >
      {platformState.language.ayuda} <SendOutlined key={"sens"} />
    </p>,
  ]);
  useEffect(() => {
    if (role > 2)
      setActions([
        <EyeOutlined
          key="eye"
          onClick={() => {
            return navigations("view", "eye");
          }}
        />,
        <EditOutlined
          key="setting"
          onClick={() => {
            return navigations("view", "setting");
          }}
        />,
        <DeleteOutlined
          key={"delete"}
          onClick={() => {
            return deleteBoard();
          }}
        />,
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  async function loadShiny() {
    let user = platformState.session;

    dispatch({
      type: types.uuidBoard,
      payload: data.uuid,
    });
    if (user) {
      dispatch({
        type: types.urlShinny,
        payload: data.url,
      });

      let index = user.boards.map((e: any) => e.uuid).indexOf(data.uuid);
      console.log(index);
      if (index > -1) {
        const hijos = await getSubCompanies(
          user.boards[index].uuidEmpresa,
          platformState.token,
          platformState.session
        );
        if (hijos && hijos.length > 0) {
          const array = hijos.map((e: any) => e.name);

          return await selectCompany(array, hijos, dispatch, navigate);
        }
        const company = await getCompanie(
          user.boards[index].uuidEmpresa,
          platformState.token,
          platformState.session
        );

        await dispatch({
          type: types.urlShinny,
          payload: data.url,
        });
        await dispatch({
          type: types.company,
          payload: company,
        });

        return navigate("view", { state: { item: user.boards[index].items } });
      }
    }
  }

  function uploadData(item: any) {
    dispatch({
      type: types.botonTitle,
      payload: platformState.language.userFormBotonEditar,
    });
    dispatch({
      type: types.botonTitle,
      payload: platformState.language.userFormBotonEditar,
    });

    dispatch({
      type: types.title,
      payload: platformState.language.modalBoardsEditar,
    });
    dispatch({
      type: types.openModal,
      payload: true,
    });
    return dispatch({
      type: types.dataUpdate,
      payload: item,
    });
  }
  const navigations = async (path: string, key: string | null = null) => {
    if (key === "eye") {
      return await loadShiny();
    } else if (key === "send") {
      return alert("hola");
    }
    return await uploadData(data);
  };

  const deleteBoard = async () => {
    confirm(
      data.uuid,
      dispatch,
      "boards",
      platformState.token,
      platformState.session
    );
  };

  const createTicket = async (item: any) => {
    try {
      let user = platformState.session;
      let env = {
        title: `El usuario ${user.name} esta reportando un ticket sobre el tablero ${item.name}`,
        description: value,
        date: Date.now(),
        image: null,
        status: "solicitado",
        user: user.idU,
      };

      const send = await postData(
        env,
        platformState.token,
        platformState.session
      );
      setValue("");
      return success(send.message);
    } catch (error) {
      console.log(error);
    }
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    await createTicket(data);
    return setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Card
        title={
          <div
            onClick={() => {
              return navigations("view", "eye");
            }}
          >
            {data.name}
          </div>
        }
        hoverable
        style={{ height: "23hv", marginTop: "1rem", marginBottom: "1rem" }}
        cover={
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              aspectRatio: "16 / 9",
            }}
            alt="example"
            src={data.image}
            onClick={() => {
              return navigations("view", "eye");
            }}
          />
        }
        actions={actions}
      >
        <Meta
          description={
            <div
              onClick={() => {
                return navigations("view", "eye");
              }}
              className="card-meta-ellipsis"
            >
              {data.description || "..."}
            </div>
          }
        />
      </Card>
      <Modal
        title={platformState.language.modalTicketTitle}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <TextArea
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={platformState.language.modalTicketDescription}
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </Modal>
    </>
  );
};

export default CardBoard;
