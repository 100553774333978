import { useContext, useEffect, useState } from "react";
import { getForUser } from "../services/rolesServices";
import { PlatformContext } from "../context/platformContext";





export const useRolesForUser = () => {
    const { platformState }: any = useContext(PlatformContext);
    const [rolesUser, setBoards]: any = useState([])

    async function data() {

        const data = await getForUser(platformState.token, platformState.session)
        if(data?.statusCode === 401){
          return  setBoards([])
        }
        
        return setBoards(data)
    }
    useEffect(() =>{
        
        data()
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[platformState.ruta] )

    return  { rolesUser }
}

