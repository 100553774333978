import React from 'react';
import { Alert, Flex, Spin } from 'antd';

const contentStyle: React.CSSProperties = {
  padding: 50,
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: 4,
};

const content = <div style={contentStyle} />;

const Spinner = () => (
  <Flex gap="large" vertical>
    <Flex gap="large">
   
      <Spin tip="Loading" size="large" fullscreen={true}>
        {content}
      </Spin>
    </Flex>
 
  </Flex>
);

export default  Spinner;