import { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { PlatformContext } from "../../context/platformContext";
import { types } from "../../types/types";
import type { MenuProps } from "antd";
//iconos
import { AreaChartOutlined, MenuFoldOutlined, MenuUnfoldOutlined, SearchOutlined, TagsOutlined, TeamOutlined } from "@ant-design/icons";

import { useRolesForUser } from "../../hooks/rolesForUserHook";
import { Button, Menu } from "antd";
import { Flex, Layout } from "antd";
import ModalForms from "../../components/modalForms";
import {
  IconAdministrato,
  IconDataPlayer,
  IconDataVikua,
  IconEmpresas,
  IconExit,
  IconInsights,
  IconMiData,
  IconMistableros,
  IconTablerosVikua,

} from "../../components/icons/iconMistableros";
import "../../styles/general.css";
import HeaderCustom from "../../components/headerCustom";
import Boards from "../boards/boards";
import Companies from "../companies/companies";
import Users from "../users/users";
import Insing from "../insitg/insing";
import Profile from "../profile/profile";
import Construccion from "./construction";
import Survey from "../survey/survey";
import LinksAdmins from "../linksAdmins/linksAdmins";
import { Search } from "../searchs/search";
import MyBoards from "../myboards/boards";
import DataVikua from "../dataVikua/dataVikua";
import Tickets from "../tickets/tickets";
import { Reports } from "../reporst/reports";
import ModalFormsTickets from "../../components/modalFormsTickets";
const { Sider, Content } = Layout;
const margen = 10
type MenuItem = Required<MenuProps>["items"][number];

const SideNav = () => {
  let history: any = useNavigate();
  const { platformState, dispatch }: any = useContext(PlatformContext);
  const { rolesUser } = useRolesForUser();

  var items: MenuItem[] = [
    {
      key: platformState.language.sidebarMisTableros,
      icon: <IconMistableros />,
      label: platformState.language.sidebarMisTableros,
      style: { marginTop: margen}
    },
    {
      key: platformState.language.sidebarTablerosVikua,
      icon: <IconTablerosVikua />,
      label: platformState.language.sidebarTablerosVikua,
      style: { marginTop: margen}
    },
    {
      key: platformState.language.sidebarInsights,
      icon: <IconInsights />,
      label: platformState.language.sidebarInsights,
      style: { marginTop: margen}
    },
    {
      key: platformState.language.sidebarDataPlayer,
      icon: <IconDataPlayer />,
      label: platformState.language.sidebarDataPlayer,
      style: { marginTop: margen}
    },
    {
      key: platformState.language.sidebarMydata,
      icon: <IconMiData />,
      label: platformState.language.sidebarMydata,
      style: { marginTop: margen}
    },
    {
      key: platformState.language.sidebarDatavikua,
      icon: <IconDataVikua />,
      label: platformState.language.sidebarDatavikua,
      style: { marginTop: margen}
    },
    {
      key: platformState.language.sidebarUsers,
      icon: <TeamOutlined  style={{ fontSize: '1.5em' }}/>,
      label: platformState.language.sidebarUsers,
      style: { marginTop: margen}
    },
    {
      key: platformState.language.sidebarEmpresa,
      icon: <IconEmpresas />,
      label: platformState.language.sidebarEmpresa,
      style: { marginTop: margen}
    },
    {
      key: platformState.language.sidebarReports,
      icon: <AreaChartOutlined  style={{ fontSize: '1.5em' }}/>,
      label: platformState.language.sidebarReports,
      style: { marginTop: margen}
    },
    {
      key: platformState.language.sidebarTickets,
      icon: <TagsOutlined style={{ fontSize: '1.5em' }}/>,
      label: platformState.language.sidebarTickets,
      style: { marginTop: margen}
    },
    {
      key: platformState.language.sidebarSearch,
      icon: <SearchOutlined style={{ fontSize: '1.5em' }}/>,
      label: platformState.language.sidebarSearch,
      style: { marginTop: margen}
    },
    {
      key: platformState.language.sidebarAdministrator,
      icon: <IconAdministrato />,
      label: platformState.language.sidebarAdministrator,
      style: { marginTop: margen}
    },
    {
      key: "auth",
      icon: <IconExit />,
      label: platformState.language.sidebarCloseSession,
      style: { marginTop: margen}
    }
    
  ];

  useEffect(() => {}, []);

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const loadTitle = (ruta: string) => {
    dispatch({
      type: types.search,
        payload: "",
    })
    dispatch({
      type: types.ruta,
      payload: ruta,
    });
  };

  const navigator = (path: string) => {
    if (path === "auth") {
      dispatch({
        type: types.token,
        payload:null
    })
    dispatch({
        type: types.session,
        payload:null
    })

      return history("/", {
        replace: true,
      });
    }
    loadTitle(path);
    return history(`${path}`);
  };
  const comprobar = (name: any) =>{
    if(name){ 
         if(platformState.menu.some((objeto: any) => objeto.name.toLowerCase().includes(name.toLowerCase()))){
          
            return true
         }  
      }
    return false
  }

  const buscarMenu  = () : Array<MenuItem> =>{
    let nuevo :Array<MenuItem>= []
    for(let i in items){
      if(platformState.language.sidebarSearch === items[i]?.key){
          if(comprobar(items[i]!.key )){
            nuevo.push(items[i])
          }
      }else if( platformState.language.sidebarAdministrator === items[i]?.key ){
        if(rolesUser?.length> 2){
          nuevo.push(items[i])
        }
      }else if(platformState.language.sidebarReports === items[i]?.key){
        if(rolesUser?.length> 1){
           nuevo.push(items[i])
        }
       
      }
      else{
        nuevo.push(items[i])
      }
    }
    return nuevo
  }
  return (
    <Flex style={{ height: "100vh" }}>
      <Layout>
        <Sider
          collapsed={collapsed}
          theme="dark"
          onCollapse={(value) => setCollapsed(value)}
          style={{ textAlign: "center" }}
        >
          <div style={{ width: "100%", display: "block", textAlign: "center" }}>
            <Button
              type="primary"
              onClick={toggleCollapsed}
              style={{ marginBottom: 16, backgroundColor: "#001529" }}
            >
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
          </div>

          <img
            id="imglogo"
            src="https://storage.googleapis.com/vikua-styles/logos/logo_small_vikua.png"
          alt="images"
         />

          <Menu
            defaultSelectedKeys={["1"]}
            mode="inline"
            theme="dark"
            inlineCollapsed={collapsed}
            items={buscarMenu()}
            onClick={(e: any) => {
              navigator(e.key);
            }}
            style={{
              textAlign: "start",
              height: "auto",
            }}
          />
        </Sider>
        <Layout
          style={{
            height: "100vh",
            maxHeight: "100vh",
            overflowY: "auto",
            overflowX: "hidden",
            backgroundColor: "#eeeeee",
          }}
        >
          <HeaderCustom />

          <Content style={{ marginTop: "3vh", padding: "2vh" }}>
            <Routes>
              <Route
                path={platformState.language.sidebarEmpresa}
                element={<Companies />}
              />
              <Route
                path={platformState.language.sidebarTablerosVikua}
                element={<MyBoards />}
              />
              <Route
                path={platformState.language.sidebarDataPlayer}
                element={<Construccion />}
              />
              <Route
                path={platformState.language.sidebarMydata}
                element={<Construccion />}
              />
              <Route
                path={platformState.language.sidebarDatavikua}
                element={<DataVikua />}
              />
              {rolesUser?.length > 2 && (
                <>
                
                <Route
                  path={platformState.language.sidebarReports}
                  element={<Reports />}
                />
                <Route
                  path={platformState.language.sidebarAdministrator}
                  element={<LinksAdmins />}
                />

                </>
              )}
               <Route
                path={platformState.language.sidebarTickets}
                element={<Tickets />

                }
              />
                  {rolesUser?.length > 1 && (
                <>
                
                <Route
                  path={platformState.language.sidebarReports}
                  element={<Reports />}
                />
               </>
              )}
              <Route
                path={platformState.language.sidebarUsers}
                element={<Users />}
              />
              <Route
                path={platformState.language.sidebarMisTableros + "/*"}
                element={<Boards />}
              />
              <Route
                path={platformState.language.sidebarInsights}
                element={<Insing />}
              />
              <Route
                path={platformState.language.sidebarSearch}
                element={<Search />}
              />
              <Route
                path={platformState.language.profile}
                element={<Profile />}
              />
              <Route
                path={platformState.language.sidebarMisTableros}
                element={<Boards />}
              />

              <Route
                path="*"
                element={
                  <Navigate
                    to={platformState.language.sidebarMisTableros}
                    replace
                  />
                }
              />
            </Routes>
            
            {rolesUser?.length > 2 ? <ModalForms roles={rolesUser.length}/>
              :<>{platformState.language.sidebarTickets ===  platformState.ruta
                  ? <ModalFormsTickets roles={rolesUser.length}/>
                  :<></>

              }

              </>  
          }
            
            <Survey />
          </Content>
        </Layout>
      </Layout>
    </Flex>
  );
};

export default SideNav;
