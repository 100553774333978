import { useContext } from "react";
import { Drawer, FloatButton } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import FormsCompanies from "../pages/companies/forms";
import { PlatformContext } from "../context/platformContext";
import { types } from "../types/types";
import FormsBoars from "../pages/boards/forms";
import FormsUsers from "../pages/users/forms";
import FormsInsitg from "../pages/insitg/forms";
import FormsLinks from "../pages/linksAdmins/forms";
import FormsMyBoars from "../pages/myboards/forms";
import FormsDataVikua from "../pages/dataVikua/forms";
import FormsTickest from "../pages/tickets/forms";
const ModalForms = ({roles}: any) => {
  const { dispatch, platformState }: any = useContext(PlatformContext);

  const showDrawer = () => {
    dispatch({
      type: types.openModal,
      payload: true,
    });
    dispatch({
      type: types.botonTitle,
      payload: platformState.language.profileBoton,
    });
    dispatch({
      type: types.title,
      payload: platformState.language.modalCrear + platformState.ruta,
    });
    return dispatch({
      type: types.dataUpdate,
      payload: null,
    });
  };

  const onClose = () => {
    dispatch({
      type: types.openModal,
      payload: false,
    });
    return dispatch ({
      type: types.test,
      payload: Math.random()
    })
  };

  return (
    <>
      <FloatButton
        type="primary"
        onClick={showDrawer}
        icon={<PlusOutlined />}
      />

      <Drawer
        title={platformState.title}
        width={520}
        closable={false}
        onClose={onClose}
        open={platformState.openModal}
      >
        {platformState.openModal && (
          <>
            {platformState.language.sidebarEmpresa === platformState.ruta && (
              <FormsCompanies close={onClose} />
            )}
            {platformState.language.sidebarMisTableros ===
              platformState.ruta && <FormsBoars close={onClose} />}
            {platformState.language.sidebarTablerosVikua ===
              platformState.ruta && <FormsMyBoars close={onClose} />}
            {platformState.language.sidebarUsers === platformState.ruta && (
              <FormsUsers close={onClose} />
            )}
            {platformState.language.sidebarInsights === platformState.ruta && (
              <FormsInsitg close={onClose} />
            )}
            {platformState.language.sidebarAdministrator === platformState.ruta && (
              <FormsLinks close={onClose} />
            )}
            {platformState.language.sidebarDatavikua === platformState.ruta && (
              <FormsDataVikua close={onClose} />
            ) }
            {platformState.language.sidebarTickets ===  platformState.ruta && (
              <FormsTickest roles={roles} />
            )

            }
          </>
        )}
      </Drawer>
    </>
  );
};

export default ModalForms;
