import { useContext } from "react";
import { Drawer } from "antd";
import { PlatformContext } from "../context/platformContext";
import { types } from "../types/types";
import FormsTickest from "../pages/tickets/forms";
const ModalFormsTickets = ({roles}:any) => {
  const { dispatch, platformState }: any = useContext(PlatformContext);



  const onClose = () => {
    dispatch({
      type: types.openModal,
      payload: false,
    });
    return dispatch ({
      type: types.test,
      payload: Math.random()
    })
  };

  return (
    <>
      <Drawer
        title={platformState.title}
        width={520}
        closable={false}
        onClose={onClose}
        open={platformState.openModal}
      >
        {platformState.openModal && (
          <>
            
            {platformState.language.sidebarTickets ===  platformState.ruta && (
              <FormsTickest roles={roles} />
            )

            }
          </>
        )}
      </Drawer>
    </>
  );
};

export default ModalFormsTickets;
