//export const api = "http://localhost:5000/api/v1/"


export const api = "https://jellyfish-app-7jjlo.ondigitalocean.app/api/v1/"

export const avatar = "https://storage.googleapis.com/vikua-styles/logos/logo_small_vikua.png"

export const pdfImage = "https://firebasestorage.googleapis.com/v0/b/images-1228d.appspot.com/o/pdfIcon.png?alt=media&token=7b4d9695-f71d-45ff-b7b9-6161c52b09ae"
    
export const NEXT_PUBLIC_POSTHOG_KEY="phc_KeHMhozCJh4KjocrSNY7HtYNsmumIbQMyCqKi3HRpny"
export const NEXT_PUBLIC_POSTHOG_HOST="https://us.i.posthog.com"

export const apiBuscador = "https://api-farmacos-xejf6zrfia-ue.a.run.app/"

export const tokenBuscador = "vikua?AGYHfZrXN4SlVlaTT7s4ObcGqyCFnjbpRkG!9KmVC?R7wSfqtP6-ATvR9rPGzGCWsGleQ88=t7hHAcVj?6HggS74zttId?LYcK2-dTjv=53p6LxGfNZQ4SGeTgW4yF/-jrlnjXSm7Bbgp5IxcqOv5yg?0f=oEavtZ?WBQzAw=O?!7qKE86eB9n2L1BAvrIVub?aCV9w1pU3mDEwigGRSL0XNz?yt8fM3bWazm4VL9?/lz4d3=n4P00JWsM"