import { Avatar, Badge, Button, Col, Dropdown, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { horaToday } from "../hooks/times";
import { PlatformContext } from "../context/platformContext";
import { useNavigate } from "react-router-dom";
import { IconHeader } from "./icons/iconHeader";
import { types } from "../types/types";
import { NotificationOutlined } from "@ant-design/icons";
import { updateNoti } from "../services/notificationsServices";


const HeaderCustom = () => {
  let history: any = useNavigate();
  const { platformState, dispatch }: any = useContext(PlatformContext);
  const [horas, setHoras] = useState<any>("");
  const [items, setItem] : any = useState([])
  const image = (): string => {
    let tem = platformState.session
    if(!tem)  {
      window.location.replace("/")
      return ""
    }else {
      
    return tem?.image || ""
    }
   
  }
  useEffect(() => {
    noti()
    setHoras(horaToday("es"));
    setTimeout(() => {
      setHoras(horaToday("es"));
    }, 60000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformState.notifications]);
  const loadTitle = (ruta: string) => {
    dispatch({
      type: types.ruta,
      payload: ruta,
    });
  };

  const noti  = () => {
    let array = []
   
    for(let i in platformState.notifications){

      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      array.push({label:(<a onClick={()=> sendUpdate(platformState.notifications[i].uuid, platformState.notifications[i].module)}>{platformState.notifications[i].title}</a>) })

    }
    return setItem(array)
  }
  async function sendUpdate(params:string, module: string) {
    try {
      let env = {
        read: true
      }
      if(module === "tickets"){
        history(`${platformState.language.sidebarTickets}`)
        loadTitle(platformState.language.sidebarTickets)
      }
     
      return await updateNoti(env, params,platformState.token, platformState.session)
    } catch (error) {
      
    }


    
  }
  return (
    <Row justify="space-between" className="content-wrapper">
      <Col span={8} style={{ paddingLeft: "2rem"}}>
        <h2 style={{ margin: "-50vh !important", marginBottom: "0%" }}>
          {platformState.ruta}
        </h2>
        <small
        
          className="subTitle"
        >
          {horas}
        </small>
      </Col>
   
      <Col span={4} style={{ textAlign: "center",  paddingTop: "0rem" }}>
      <Dropdown menu={{items}} placement="bottomRight">
        <Button style={{borderRadius: "50%", width: 40, height:40, border: 0}}><Badge size="small" count={platformState.notifications.length}><NotificationOutlined size={50}/></Badge></Button>
      </Dropdown>
        <Avatar
          style={{ cursor: "pointer", marginInline: 10}}
          size={{ xs: 32, sm: 42, md: 64, lg: 64, xl: 70, xxl: 70 }}
          icon={<IconHeader url={image()} />}
          onClick={()=> {
            history(`${platformState.language.profile}`);
            loadTitle(platformState.language.profile)
          }}
        />
      </Col>
    </Row>
  );
};

export default HeaderCustom;
