import { useContext, useEffect, useState } from "react"
import { PlatformContext } from "../context/platformContext"
import { getData } from "../services/dataVikuaServices"

export const useDataVikua = () => {
    const [boards, setBoards]: any = useState(null)
    const { platformState }: any = useContext(PlatformContext)
    async function data() {
        setBoards([])
        if(platformState.session){
            const dato: any = await getData(platformState.limit, platformState.skip, platformState.search,platformState.token, platformState.session)
          
            if(!dato || dato.message === 'Unauthorized'){
                
              return localStorage.clear();
            }        
            return setBoards(dato || [])
        }
      
   
        return setBoards([])
    }
    useEffect(() =>{
        
        data()
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[platformState.openModal, platformState.test, platformState.skip, platformState.search] )

    return  { boards }
}