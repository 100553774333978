import { idioma } from "../assets/langujae"
import { types } from "../types/types"

const idiomas: any = idioma;
const loadLanguaje = (item: string, user : any, dispatch: any) =>{
    dispatch({
        type: types.ruta,
        payload: idiomas[item].sidebarMisTableros
    })
    dispatch({
        type: types.dataProfile,
        payload: user
    })
    return dispatch({
        type: types.language,
        payload: idiomas[item]
    })
}

export {
    loadLanguaje
}