import { useContext, useEffect } from "react";
import "../../styles/users.css";
import { PlatformContext } from "../../context/platformContext";

import { Button, Flex, Tag } from "antd";
import Spinner from "../../components/spinner";
import { TableCustom } from "../../components/table";
import {
  EyeOutlined,
} from "@ant-design/icons";
import { types } from "../../types/types";
import { useTickets } from "../../hooks/ticketsHooks";

const Tables = () => {
  const { platformState, dispatch }: any = useContext(PlatformContext);
  const { tickest} = useTickets()

  const columns = [
    {
      title: "#",
      dataIndex: "image",
      key: "image",
      render: (image: string) => {
        return (
        <></>
        );
      },
    },
    {
      title: platformState.language.tickestTitle,
      dataIndex: "title",
      key: "title",
    },
    {
      title: platformState.language.tickestDescription,
      dataIndex: "description",
      key: "description",
    },
    {
      title: platformState.language.tableFecha,
      dataIndex: "date",
      key: "date",
      render: (date: string) => {
        return <Tag key={date}>{formatearFechaCreacion(date)}</Tag>;
      },
    },
    {
      title: platformState.language.tableAcciones,
      dataIndex: "uuid",
      key: "uuid",
      render: (uuid: string) => {
        return (
          <Flex gap="small" wrap>
             <Button
              onClick={() => {
                uploadData(uuid);
              }}
            >
            <EyeOutlined />
            </Button>
          </Flex>
        );
      },
    },
  ];
  useEffect(() => {}, [platformState.test]);

  
  function uploadData(item: any) {
    const data = tickest.find((m: any) => m.uuid === item)

    dispatch({
      type: types.botonTitle,
      payload: platformState.language.userFormBotonEditar,
    });
    dispatch({
      type: types.title,
      payload: platformState.language.modalTickestTitleEdit,
    });
    dispatch({
      type: types.openModal,
      payload: true
    })
    return dispatch({
      type: types.dataUpdate,
      payload: data,
    });
  }
  function formatearFechaCreacion(data: any) {
    let fecha = new Date(data);
    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1; // Los meses en JavaScript van de 0 a 11, por lo que se suma 1
    const ano = fecha.getFullYear();

    // Formatear en un string con el formato deseado
    return `${dia < 10 ? "0" : ""}${dia}/${mes < 10 ? "0" : ""}${mes}/${ano}`;
  }

  return (
    <>
      {!tickest ? <Spinner /> : <TableCustom columns={columns} data={tickest} />}
    </>
  );
};

export default Tables;
