import React, { useContext, useEffect, useState } from "react";
import { Card } from "antd";
import { Button, Form, Input } from "antd";
import {
  EditOutlined
} from "@ant-design/icons";

import { Typography } from 'antd';
import { putProfile } from "../../services/usersServices";
import { PlatformContext } from "../../context/platformContext";

const { Text } = Typography;


const CardProfileForm = ({labels, user, limpiar}: any) => {
  const { platformState, dispatch }: any = useContext(PlatformContext);
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState<Boolean>(false)
  const [chagePassword, setChangePassword] = useState<Boolean>(false)
  useEffect(()=>{
    form.setFieldsValue(user)
  },[user])
  const update = async() => {
    
   
    let {email, password, name, lastname, dni, newPassword, phone} = form.getFieldsValue()
    let env = {
      email: email,
      name: name,
      lastname: lastname,
      dni: dni,
      password: password,
      newPassword: newPassword || "",
      phone:  phone,
      image: user.image,
      area: user.area
  }

  

     const resp = await putProfile(env,user.uuid,platformState.token, platformState.session)
     setIsActive(!isActive)
      return limpiar(resp)
  }
  function validatePhoneNumber(phoneNumber:string) {
    const regex = /^\+?([0-9]{1,15})[- ]?([0-9]{3,15})$/;
    return regex.test(phoneNumber);
  }
  return(
  <Card size="small" extra={<a onClick={()=> setIsActive(!isActive)} >{labels.profileEditar} &nbsp; <EditOutlined width="2rem" /></a>}>
    <Form
      name="wrap"
      labelCol={{ flex: "110px" }}
      labelAlign="left"
      labelWrap
      wrapperCol={{ flex: 1 }}
      colon={false}
      form={form}
      onFinish={update}
 
    >
      <Form.Item
        label={labels.profileName}
        name="name"
        rules={[{ required: true }]}
      >
        <Input type="text" disabled={!isActive} />
      </Form.Item>
      <Form.Item
        label={labels.profileLastName}
        name="lastname"
       
      >
        <Input type="text" disabled={!isActive} />
      </Form.Item>
      <Form.Item
        label={labels.profileEmail}
        name="email"
        rules={[{ required: true }]}
      >
        <Input type="text" disabled={!isActive} />
      </Form.Item>
      <Form.Item
        label={labels.profileDni}
        name="dni"
        
      >
        <Input type="text" disabled={!isActive} />
      </Form.Item>
      <Form.Item
        label={labels.profilePhone}
        name="phone"
        rules={[{ required: true },

          ({ getFieldValue }) => ({
            validator(_, value) {
              if (validatePhoneNumber(getFieldValue('phone')) ) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The number phone no valid!'));
            },
          }),
        ]}
      >
        <Input type="text" disabled={!isActive} />
      </Form.Item>
      <Form.Item
        label={labels.profilePassword}
        name="password"
        rules={[{ required: true }, {min: 6}]}
      >
        <Input type="password" disabled={!isActive}/>
      </Form.Item>
      {chagePassword && (
            <Form.Item
            label={labels.profileNuevaContraseña}
            name="newPassword"
            rules={[  {min: 6}]}
          >
            <Input type="password" disabled={!isActive}/>
          </Form.Item>
      ) 

      }
      <Form.Item label=" " style={{ textAlign: "start"}}>
        <Text className="textPassword" onClick={()=> setChangePassword(!chagePassword)}>{labels.profileRecuperate}</Text>
       
      </Form.Item>
      <Form.Item label=" " style={{ textAlign: "end"}}>
        <Button type="primary" htmlType="submit">
         {labels.profileBoton}
        </Button>
      </Form.Item>
    </Form>
  </Card>
);
}
export default CardProfileForm;
