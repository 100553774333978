import { Avatar, Button, Form, Input, Select } from "antd";
import { IconHeader } from "../../components/icons/iconHeader";
import { useContext, useEffect, useState } from "react";
import { PlatformContext } from "../../context/platformContext";
import { EditOutlined } from "@ant-design/icons";
import { pdfImage } from "../../environment";
import { useCompanies } from "../../hooks/companiesHook";
import { getCompanie, getCompanies, getSubCompanies } from "../../services/companiesServices";
import { postInsing, putInsing } from "../../services/insingServices";
import { types } from "../../types/types";

const FormsInsitg = ({ close }: any) => {
  const { platformState, dispatch }: any = useContext(PlatformContext);
  const [form] = Form.useForm();
  const [image, setImage] = useState(pdfImage);
  const { companies } = useCompanies();
  const [subcompanies, setSubcompanies] = useState([]);

  useEffect(()=>{
  
    if(platformState.dataUpdate) {
      
      setImage(platformState.dataUpdate.image)
        form.setFieldValue('title', platformState.dataUpdate.title)
        form.setFieldValue('description', platformState.dataUpdate.description)
        getFathercompany(platformState.dataUpdate.company[0])
       

    }else {
      limpiar()
    }
  },[platformState.openModal, platformState.dataUpdate])

  const action = async () => {
    if (platformState.dataUpdate) return await updateData()
    return await sendData()
  };

  async function getFathercompany(text: string){
   
    let allCopanies = await getCompanies(platformState.token, platformState.session)
    let index = allCopanies.map((e:any) => e.uuid).indexOf(text)
      
    if(index < 0){
      
      let oneCompany = await getCompanie(text,platformState.token, platformState.session);
      const data = await getSubCompanies(oneCompany.father,platformState.token, platformState.session);
      setSubcompanies(data);
      form.setFieldValue('project', oneCompany.father)
      return form.setFieldValue('subcompanyId', text)
    }else {
     
      return  form.setFieldValue('project', platformState.dataUpdate.company[0])
    }
    
    

  }
  const cargarImagenTobase64 = async () => {
    let img: any = await document.getElementById("file-input");
    let archivo = img.files[0];
    const reader: any = new FileReader();

    reader.readAsDataURL(archivo);

    reader.onload = () => {
      setImage(reader.result);
    };
  };

  const handleChange = async (text: string) => {
    
    const data = await getSubCompanies(text,platformState.token, platformState.session);
    setSubcompanies(data);
    if (data.length === 0) {
      setSubcompanies([]);
    }
  };

  const sendData = async () => {

    
    if (image !== pdfImage) {
        let uuid : any = JSON.parse(localStorage.getItem("session") || "")
        let { title, description, project, subcompanyId} = form.getFieldsValue()
        let env = {
            title: title,
            description: description,
            creator: uuid.idU,
            company: [subcompanyId || project],
            image: image


        }
        
        await postInsing(env,platformState.token, platformState.session)

        limpiar()
        return close()
    }

}
const updateData = async () => {

    
  if (image !== pdfImage) {
     
      let { title, description, project, subcompanyId} = form.getFieldsValue()
      
      let env = {
          title: title,
          description: description,
          company: [subcompanyId || project],
          image: image,
          name: platformState.dataUpdate.name


      }

      await putInsing(env, platformState.dataUpdate.uuid,platformState.token, platformState.session)

      limpiar()
      return close()
  }

}
function limpiar() {
  form.resetFields()
  setImage(pdfImage);
  setSubcompanies([])
  return dispatch({
      type: types.test,
      payload: Math.random()
  })

}
  return (
    <>
      <Form
        name="wrap"
        labelCol={{ flex: "110px" }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        form={form}
        onFinish={action}
        style={{ textAlign: "center" }}
      >
        <Avatar
          style={{ marginBottom: 10 }}
          size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
          icon={<IconHeader url={pdfImage} />}
        />
        <div className="image-upload" style={{ marginBottom: 10 }}>
          <label htmlFor="file-input" onClick={() => {}}>
            {platformState.language.profileEditar} &nbsp;{" "}
            <EditOutlined width="2rem" />
          </label>
          <input
            id="file-input"
            onChange={() => cargarImagenTobase64()}
            type="file"
          />
        </div>

        <Form.Item
          label={platformState.language.profileName}
          name="title"
          rules={[{ required: true }]}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label={platformState.language.profileName}
          name="description"
          rules={[{ required: true }]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item label={platformState.language.companyTypeTwo} name="project">
          <Select
            defaultValue={''}
            onChange={handleChange}
            options={[
              {
                value: null,
                label: platformState.language.companyTypeThree,
              },
              ...companies.map((e: any) => {
                return {
                  value: e.uuid,
                  label: e.name,
                };
              }),
            ]}
          />
        </Form.Item>
        {subcompanies.length > 0 && (
          <Form.Item
            label={platformState.language.userFormCompania}
            name="subcompanyId"
          >
            <Select
              defaultValue={''}
              options={[
                {
                  value: null,
                  label: platformState.language.companyTypeThree,
                },
                ...subcompanies.map((e: any) => {
                  return {
                    value: e.uuid,
                    label: e.name,
                  };
                }),
              ]}
            />
          </Form.Item>
        )}

        <Form.Item label=" " style={{ textAlign: "end" }}>
          <Button type="primary" htmlType="submit">
            {platformState.botonModalForm}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default FormsInsitg;
