import { useContext, useState } from "react";
import { useSurvey } from "../../hooks/survey";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
} from "recharts";
import { Card, Col, Row, Select, Spin } from "antd";
import { PlatformContext } from "../../context/platformContext";
import {
  getGrapichSurvey,
  getGrapichSurveyDetail,
} from "../../services/reporteService.";
const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "red", "pink"];

export const Surveys = () => {
  const { platformState }: any = useContext(PlatformContext);
  const surveys = useSurvey().surveys;
  const [data, setData] = useState([]);
  const [pie, setpie]: any = useState();
  const [loading, setLoading] = useState(true);
  const [detalles, setDetalles] = useState([]);

  const handleChange = async (value: string) => {
    if (value === "none") return;
    setDetalles([]);
    setLoading(false);
    const datos = await getGrapichSurvey(
      value,
      platformState.token,
      platformState.session
    );
    setData(datos.array);
    setpie({ total: datos.users, read: datos.responses });
    const details = await getGrapichSurveyDetail(
      value,
      platformState.token,
      platformState.session
    );
    setDetalles(details);
    return setLoading(true);
  };

  const getPath = (x: any, y: any, width: any, height: any) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${
      x + width / 2
    },${y + height / 3}
    ${x + width / 2}, ${y}
    C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
      x + width
    }, ${y + height}
    Z`;
  };

  const TriangleBar = (props: any) => {
    const { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };
  const Chart = () => {
    return (
      <>
        {" "}
        {loading ? (
          <>
            {" "}
            <p>Encuestas por usuarios</p>
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="id" />
              <YAxis />
              <Bar
                dataKey="number"
                fill="#8884d8"
                shape={<TriangleBar />}
                label={{ position: "top" }}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                ))}
              </Bar>
            </BarChart>
          </>
        ) : (
          <Spin />
        )}
      </>
    );
  };

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  return (
    <>
      <Row justify={"end"}>
        <Col>
          <Select
            defaultValue="none"
            onChange={handleChange}
            options={[
              {
                value: "none",
                label: platformState.language.companyTypeThree,
              },

              ...surveys.map((e: any) => {
                return {
                  value: e.uuid,
                  label: e.name,
                };
              }),
            ]}
          />
        </Col>
      </Row>
      <Row justify={"space-evenly"}>
        <Col>
          <Card style={{ minHeight: 500, minWidth: 500 }}>
            <Chart />
            {data.map((e: any) => {
              return (
                <>
                  <p style={{ fontSize: 10, margin: 0 }}>
                    {e.id} - {e.question}
                  </p>
                </>
              );
            })}
          </Card>
        </Col>
        <Col>
          <Card style={{ minHeight: 500, minWidth: 500 }}>
            {loading ? (
              <>
                {pie && (
                  <>
                    {" "}
                    <p>Usuarios por encuesta</p>
                    <PieChart width={400} height={285}>
                      <Pie
                        data={[
                          { value: pie.read },
                          { value: pie.total - pie.read },
                        ]}
                        outerRadius={90}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {[
                          { value: pie.read },
                          { value: pie.total - pie.read },
                        ].map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                    <p style={{ fontSize: 10, margin: 0 }}>
                      Total de usuarios registrados : {pie.total}{" "}
                    </p>
                    <p style={{ fontSize: 10, margin: 0 }}>
                      Usuarios que contestaron la encuesta: {pie.read} -{" "}
                      {((pie.read * 100) / pie.total).toFixed(0)}%{" "}
                    </p>
                    <p style={{ fontSize: 10, margin: 0 }}>
                      Usuarios que no contestaron la encuesta :{" "}
                      {pie.total - pie.read} -{" "}
                      {(((pie.total - pie.read) * 100) / pie.total).toFixed(0)}%{" "}
                    </p>
                  </>
                )}
              </>
            ) : (
              <Spin />
            )}
          </Card>
        </Col>
      </Row>
      {detalles.length > 0 && (
        <Row justify={"space-evenly"} style={{marginTop:"1em"}}>
          {detalles.map((e: any) => {
            return (
              <Col>
                <Card style={{ height: 350, width: 250 }}>
                  <p style={{ fontSize: 10, margin: 0 }}>{e.pregunta}</p>
                  <PieChart width={200} height={200}>
                    <Pie
                      data={e.respuestas}
                      outerRadius={90}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      fill="#8884d8"
                      dataKey="number"
                    >
                      {e.respuestas.map((entry: any, index: any) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                  {e.respuestas.map((dat: any) => {
                    return (
                      <p style={{ fontSize: 10, margin: 0 }}>
                        {dat.name} - {dat.number}
                      </p>
                    );
                  })}
                </Card>
              </Col>
            );
          })}
        </Row>
      )}
    </>
  );
};
