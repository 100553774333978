
import { api } from '../environment';
import { Tracking } from '../interfaces/tracking';
import { types } from '../types/types';
import { error } from './alertsServices';
import { postTracking } from './tracking';

let tracking: Tracking = {
  uuidSesion: "",
  activity: "",
  description: ""

}

const getData = async (limit: any, skip: any, search: any, token: string, sessionTem : any) => {

  if (!sessionTem) return
 let {session} = sessionTem
  let options = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    }
  }

  try {
    tracking.activity = "Get ticket"
    tracking.description = "Get all ticket"
    tracking.uuidSesion = session
    await postTracking(tracking, token, sessionTem )
    const login = await fetch(`${api}ticket?limit=${limit}&offset=${skip}&search=${search}`, options);
    const data = await login.json();
    return data;
  } catch (e) {
    return e;
  }
}


const postData = async (data: any, token: string, sessionTem : any) => {

  if (!sessionTem) return
 let {session} = sessionTem 
  let options = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    },
    body: JSON.stringify(data)
  }

  try {

    tracking.activity = "Create ticket"
    tracking.description = "Create a new ticket"
    tracking.uuidSesion = session
    await postTracking(tracking, token, sessionTem )
    const login = await fetch(api + "ticket", options
    );
    const data = await login.json();
    return data;
  } catch (e) {
    return e;
  }
}

const updateData = async (data: any, id: string, token: string, sessionTem : any) => {


  if (!sessionTem) return
 let {session} = sessionTem
  let options = {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    },
    body: JSON.stringify(data)
  }

  try {

    tracking.activity = "Update ticket"
    tracking.description = "Update a board"
    tracking.uuidSesion = session
    await postTracking(tracking, token, sessionTem )
    const login = await fetch(api + "ticket/" + id, options
    );
    const data= await login.json();
    return data;
  } catch (e) {
    return e;
  }
}

const deleteData = async (id: string, dispatch: any, token: string, sessionTem : any) => {

 let {session} = sessionTem
  let options = {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    }
  }

  try {
    tracking.activity = "Delete Board"
    tracking.description = "Delete a Borad"
    tracking.uuidSesion = session
    await postTracking(tracking, token, sessionTem )

    const login = await fetch(api + "ticket/" + id, options
    );
    const data = await login.json();
    if (data.error) {

    } else {
      dispatch({
        type: types.test,
        payload: Math.random()
      })
    }

    return data;
  } catch (e) {

    error("No se elimino el ticket")
    return e;
  }
}

const getDataForId = async(id : string, token: string, sessionTem : any) => {

  let {session} = sessionTem
  let options = {  
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}` ,
      'session': `${session}`      
    }
  }

      try{
       tracking.activity = "Get Companies"
        tracking.description = "Get all companies"
        tracking.uuidSesion = session
        await postTracking(tracking, token, sessionTem )
    
        const login = await fetch(api+"myboards/"+id, options
        );
        const data = await login.json();
        return data;
      }catch(e){
        return e;
      }
}
export {
  getData, postData, updateData, deleteData, getDataForId
}