import { useContext, useEffect, useState } from "react";
import "../../styles/profile.css"
import { PlatformContext } from "../../context/platformContext";

import { types } from "../../types/types";

import { Col, Row } from "antd";
import CardProfile from "./cardProfile";
import CardProfileForm from "./cardProfileForm";
const Profile = () => {
    const { platformState, dispatch }: any = useContext(PlatformContext)

    const user: any = platformState.session



    useEffect(() => {
      

        
  
    }, [platformState.test])

    function limpiar(resp: any) {
        let user: any = JSON.parse(localStorage.getItem("session") || "")

        let session = {
            idU: user.idU,
            name: resp.name || user.name,
            lastname: resp.lastname || user.lastname,
            email: resp.email || user.email,
            image: resp.image || user.image,
            area: resp.area || user.area,
            dni: resp.dni || user.dni,
            token: user.token,
            address: user.address,
            company : user.company,
            createAt: user.createAt,
            creator: user.creator,
            lastAccess : user.lastAccess, 
            role : user.role,
            status : user.status,  
            uuid : user.uuid,
            phone: resp.phone


        }
      
        localStorage.setItem("session", JSON.stringify(session))       
        return dispatch({
            type: types.test,
            payload: Math.random()
        })

    }

 

    return (
        <>
            <Row justify="space-between"  gutter={{ xs: 24, md: 12}}>
                <Col xs={24} md={16}>
                <CardProfileForm labels={platformState.language} user={user} limpiar={limpiar}/> 
                </Col>
                <Col xs={24} md={8}>
                 <CardProfile user={user} labels={platformState.language} limpiar={limpiar} />
                </Col>
            </Row>
        </>
    )
}

export default Profile;