import { useState } from "react";
import { DataLabels } from "../../interfaces/dataLabels";
import { Button, Form, Input, List, Select, Tag } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
const DataLabel = ({ platformState, dataLabels, setDataLabels }: any) => {
  
  const [typeLabel, setTypeLabel] = useState<string | null>("ninguno");

  const [form] = Form.useForm();

  const borraritem = (item: DataLabels): void => {
    const index = dataLabels.indexOf(item);
    console.log(index);
    if (index > -1) {
      let array = dataLabels.slice();
      array.splice(index, 1);
      setDataLabels(array);
    }
  };

  const verificarDatalabel = (): void => {
  
    let { nameLabel, valuesLabel } = form.getFieldsValue();

    
    if (!nameLabel) return;
    let env: DataLabels = {
      label: "",
      type: "",
      values: "",
    };
    let array = dataLabels.slice();
   
    switch (typeLabel) {
      case "input":
        env.label = nameLabel;
        env.type = typeLabel;
        env.values = valuesLabel;
        array.push(env);
        setDataLabels(array);
        limpiarLabels();
        break;
      case "readonlyInput":
        env.label = nameLabel;
        env.type = typeLabel;
        env.values = valuesLabel;
        array.push(env);
        setDataLabels(array);
        limpiarLabels();
        break;
      case "select":
        env.label = nameLabel;
        env.type = typeLabel;
        env.values = valuesLabel.split(";");
        array.push(env);
        setDataLabels(array);
        limpiarLabels();
        break;
      default:
        break;
    }
  };

  const limpiarLabels = () => {
    setTypeLabel("ninguno");
  
    form.resetFields()
  };

  const onChange = (value: string) => {
    
    setTypeLabel(value);
  };

  const ValuesItems = ({value}: any) =>{
  
    if (!value) return <></>
    if(typeof(value) === "string" ){
      return <>Values {value}</> 
    }
    return (
      <>Values {value.map((e: string )=> <Tag>{e}</Tag>)

      }
      </>
    )
  }
  return (
    <>
      <Form form={form} onFinish={verificarDatalabel}>
        <Form.Item
          label={platformState.language.modalBoardsDataLabelsName}
          name="nameLabel"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={platformState.language.modalBoardsDataLabelsType}
          name="typeLabel"
          rules={[{ required: true }]}
        >
          <Select onChange={onChange}>
            <Select.Option value="ninguno">ninguno</Select.Option>
            <Select.Option value="input">Input editable</Select.Option>
            <Select.Option value="readonlyInput">
              Input no editable
            </Select.Option>
            <Select.Option value="select">Select</Select.Option>
          </Select>
        </Form.Item>

        {typeLabel === "select" || typeLabel === "readonlyInput" ? (
          <Form.Item
            label={platformState.language.modalBoardsDataLabelsValues}
            name="valuesLabel"
          >
            <Input />
          </Form.Item>
        ) : (
          <></>
        )}

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="button" onClick={verificarDatalabel}>
            agregar
          </Button>
        </Form.Item>
      </Form>

      <List
        itemLayout="horizontal"
        dataSource={dataLabels}
        renderItem={(item: any, index) => (
          <List.Item
          actions={[<a key="list-loadmore-edit" onClick={()=>borraritem(item)}> <DeleteOutlined /></a>,]}
          >
            <List.Item.Meta
              title={item.label}
              description={<ValuesItems value={item.values} />}
            />
            <p>{item.type}</p>
          </List.Item>
        )}
      />
    </>
  );
};

export default DataLabel;
