import { Button, Form, Input, Select } from "antd";
import { useEffect, useState } from "react";

const DataLabelsCompany = ({
  dataLabels,
  newLabels,
  setNewLables,
  setDataLabels,
}: any) => {
  let array = newLabels?.slice();
  const [form] = Form.useForm()
  useEffect(() => {
    console.log(">>>>", dataLabels);
    for (let i in dataLabels) {
      if (dataLabels[i].type === "readonlyInput") {
        array[i] = {
          valueAsignado: dataLabels[i].values,
          ...dataLabels[i],
        };
        setNewLables(array);
      }else{
        console.log(dataLabels[i].label, dataLabels[i].valueAsignado)
        form.setFieldValue(`${dataLabels[i].label}`, dataLabels[i].valueAsignado)
      }

    }
  }, [dataLabels]);

  const onChangeValues = (label: string) => {
    let value = form.getFieldValue(label)
   
    for (let i in dataLabels) {
      if (dataLabels[i].label === label) {
      
        array[i].valueAsignado = value
         
        
      }
    }
 
    return setNewLables(array);
  };

  const limpiar = (text: any) => {
    const match = text.replace(/\{|\}|\s+/g, " ");
    if (match) {
      return match; // Salida: dataset: diaz
    }
  };

  const borrarDataLabels = () => {
  
    setNewLables(null);
    setDataLabels([]);
    array = [];
  };
  return (
    <>
      <Form name="basic" form={form}>
        <>
          {dataLabels?.map((e: any) => {
            return (
              <>
                {e.type === "input" && (
                  <Form.Item label={e.label} name={e.label}>
                    <Input onChange={()=> onChangeValues(e.label)}  />
                  </Form.Item>
                )}
                {e.type === "select" && (
                  <Form.Item label={e.label} name="typeLabel">
                    <Select  onChange={()=> onChangeValues(e.label)} >
                      <Select.Option value="ninguno">ninguno</Select.Option>
                      {e.values.map((m: any)=>{
                        return(
                          <Select.Option value={m}>
                            {m}
                        </Select.Option>
                        )
                      })}
                    
                    
                    </Select>
                  </Form.Item>
                )}
              </>
            );
          })}
        </>
      </Form>
    </>
  );
};

export default DataLabelsCompany;
