
import {api} from '../environment';
import { Tracking } from '../interfaces/tracking';
import { postTracking } from './tracking';

let tracking: Tracking = {
  uuidSesion: "",
  activity: "",
  description: ""
}

const getRoles = async(token: string, sessionTem : any) => {

  if (!sessionTem) return
let {session} = sessionTem
  let options = {  
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}` ,
      'session': `${session}`
    }
  }

      try{
       tracking.activity = "Get Roles"
        tracking.description = "Get all roles"
        tracking.uuidSesion = session
        await postTracking(tracking, token, sessionTem )
    
        const login = await fetch(api+"roles", options
        );
        const data = await login.json();
        return data;
      }catch(e){
        return e;
      }
} 

const getForUser = async(token: string, sessionTem : any) => {

  if (!sessionTem) return
let {session} = sessionTem
  let options = {  
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}` ,
      'session': `${session}`
    }
  }

      try{
       
        tracking.activity = "Get Roles"
        tracking.description = "Get all roles"
        tracking.uuidSesion = session
        await postTracking(tracking, token, sessionTem)
    
        const login = await fetch(api+"roles/findforRole", options
        );
        const data = await login.json();
        return data;
      }catch(e){
        return e;
      }
} 
export {
    getRoles, getForUser
}