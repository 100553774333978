import { useContext, useEffect, useMemo, useState } from 'react';
import io from 'socket.io-client';
import { PlatformContext } from '../context/platformContext';

const so: any = io

export const useSocket = ( serverPath: string ) => {
    const { platformState }: any = useContext(PlatformContext)
  
    const socket = useMemo(() => so.connect( serverPath,
        { 
            transports: ['websocket'],
            autoConnect: true,
            forceNew: true,
            auth:{
                authentication: platformState.token
            }
           
        // eslint-disable-next-line react-hooks/exhaustive-deps
        } ), [ serverPath ] );
    const [ online, setOnline ] = useState(false);

    useEffect(() => {
        setOnline( socket.connected );
    }, [socket])

    useEffect(() => {
        socket.on('connect', () => setOnline( true ));
    }, [ socket ])

    useEffect(() => {
        socket.on('disconnect', () => setOnline( false ));
    }, [ socket ])

    return {
        socket,
        online
    }
}
