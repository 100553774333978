import { useContext, useEffect, useState } from "react";
import "../../styles/mistableros.css"
import { PlatformContext } from "../../context/platformContext";
import { getCompanie } from "../../services/companiesServices";
const ShinyView = ({item}: any) => {
  const { platformState }: any = useContext(PlatformContext)
  const [companyUser, setCompanyUser]: any = useState(null)

  useEffect(() => {




ciclo()
  }, []);

  const ciclo = async () => {
 
       if(platformState.company){
        let iframeElement: any = document.getElementById('view');

        iframeElement.addEventListener('load', miFuncion);
       }
    
    
  }
   function miFuncion() {

    let iframe: any = document.getElementById('view');
    let user: any | null = platformState.session
    let board =  user.boards.map((e: any) => e.uuid).indexOf( platformState.uuidBoard);
    iframe.contentWindow.postMessage(JSON.stringify({email: user.email, board: board === -1 ? [] : user.boards[board].items, "data_labels":platformState.company["data_labels"]}), '*');

  }
  return (<>
    {platformState.company
      ? <iframe id="view" src={platformState.shiny} ></iframe>
      : <></>
    }


  </>)
}

export default ShinyView;