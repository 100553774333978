import { Row } from "antd";
import construccion from  "../../assets/AccesoBloqueado.png"

const Construccion = () => {

    return(
    <Row justify={"center"} align={"middle"}>
    
        <img  style={{height:"60vh"}}  src={construccion} />
    </Row>)
}

export default Construccion;