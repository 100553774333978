import { useContext, useEffect, useState } from "react";
import { getBoards } from "../services/boardsServices";
import { PlatformContext } from "../context/platformContext";
import { types } from "../types/types";

export const useBoards = () => {
    const [boards, setBoards]: any = useState(null)
    const { platformState, dispatch }: any = useContext(PlatformContext)
    async function data() {
        setBoards([])
        if(platformState.token){
            const dato: any = await getBoards(platformState.limit, platformState.skip, platformState.search, platformState.token, platformState.session)
         
            if(!dato || dato.message === 'Unauthorized'){
                dispatch({
                    type: types.session,
                    payload: null
                  })
             dispatch({
                type: types.token,
                payload: null
              });
               return setBoards(null)
            }        
            return setBoards(dato)
        }
      
   
        return setBoards(null)
    }
    useEffect(() =>{
        
        data()
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[platformState.openModal, platformState.test, platformState.skip, platformState.search] )

    return  { boards }
}

