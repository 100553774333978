import { useEffect, useState, useContext } from "react";
import { getMyBoards } from "../services/myboardsServices";
import { PlatformContext } from "../context/platformContext";
import { types } from "../types/types";




export const useMyBoards = () => {
    const [boards, setBoards]: any = useState(null)
    const { platformState, dispatch }: any = useContext(PlatformContext)
    async function data() {
        setBoards([])
        if(platformState.session){
            const dato: any = await getMyBoards(platformState.limit, platformState.skip, platformState.search,platformState.token, platformState.session)
          
            if(!dato || dato.message === 'Unauthorized'){
                
                dispatch({
                    type: types.token,
                    payload:null
                })
                dispatch({
                    type: types.session,
                    payload:null
                })
                return
            }        
            return setBoards(dato || [])
        }
      
   
        return setBoards([])
    }
    useEffect(() =>{
        
        data()
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[platformState.openModal, platformState.test, platformState.skip, platformState.search] )

    return  { boards }
}

