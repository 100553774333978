import { useContext, useEffect } from "react";

import { loadLanguaje } from "../hooks/loadLanguje";
import { PlatformContext } from "../context/platformContext";
import { useValidate } from "../hooks/protectRoutes";
import SideNav from "../pages/shared/Sidenav";
import { useSesion } from "../hooks/veirficateSesion";
import { SocketProvider } from "../context/SocketContext";


const DashboardRouter = () => {
     const {  platformState, dispatch }: any = useContext(PlatformContext)
     const {selectempresa} = useValidate()
     const { users} = useSesion()
     useEffect(() =>{
       
          let idioma : string =  localStorage.getItem("idioma") ||  'es'
          
          loadLanguaje(idioma, platformState.session, dispatch);
          
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])
 return(
     <SocketProvider>{selectempresa}{users}
         <SideNav />  
      
    </SocketProvider>)
}

export default DashboardRouter;