import { useState, useEffect, useContext } from 'react';
import { PlatformContext } from '../context/platformContext';
import { types } from '../types/types';

interface DebounceOptions {
  wait?: number;
}

export function useDebounce<T>(value: T, options?: DebounceOptions): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const {  dispatch }: any = useContext(PlatformContext);
  const { wait = 300 } = options || {};

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      dispatch({
        type: types.search,
        payload: value,
      });
    }, wait);

    return () => clearTimeout(handler);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, wait]);

  return debouncedValue;  

}