import { getdata, sendLogin, verificarLogin } from "../services/authServices"


const login = async (data:any) => {
    
    const callData = await sendLogin(data);
    return callData;

}
const verificar = async (data:any) => {
    
    const callData = await verificarLogin(data);
    return callData;

}
const getMain = async (id: string) => {
    const data = await getdata(id)
    return data
}
export {
    login,
    getMain,
    verificar
}