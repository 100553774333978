export const idioma = {
    "es": {
        loginTitleForm: "Bienvenido",
        loginVerificateForm: "Introduce el código de verificación",
        loginEmail: "Correo",
        loginPlaceholderEmail: "Ingresa tu correo",
        loginPassword: "Contraseña",
        loginPlaceholderPassword: "Ingresa tu contraseña",
        loginRecuperatePassword: "Olvide mi contraseña",
        loginButtonSendLongin: "Entrar",
        loginButtonPrimiun: "Vikua premiun",
        loginSubtutleFrom: "No tienes una cuenta?",
        loginCreatePassword: "Registrate aquí",
        authTitleOne: "La vida, mejor",
        authTitleTwo: "Toda tu data",
        authTitleThree: "en un solo lugar",
        // menu
        sidebarServices: "Servicios",
        sidebarMisTableros: "Mis tableros",
        sidebarTablerosVikua: "Tableros Vikua",
        profile: "Perfil",
        mistablerosTitle: "Tableros",
        sidebarUsers: "Usuarios",
        sidebarInsights: "Insights",
        sidebarDataPlayer:"Data Player",
        sidebarData:"Data",
        sidebarMydata:"Mi data",
        sidebarDatavikua:"Data vikua",
        sidebarMiembros:"Miembros",
        sidebarEmpresa:"Empresas",
        sidebarConfig:"Configuraciones",
        sidebarAdministrator:"Administradores",
        sidebarCloseSession:"Cerrar sesion",
        sidebarSearch: "Buscar",
        sidebarTickets:"Tickets",
        sidebarReports:"Reportes",

        // perfil
        profileName: "Nombre",
        profileLastName: "Apellido",
        profileEmail: "Correo",
        profilePhone: "Teléfono",
        profilePassword: "Introduce tu Contraseña",
        profileRecuperate: "Cambiar mi contraseña",
        profileBoton: "Guardar",
        profileEditar: "Editar",
        profileDni: "DNI",
        profileArea: "Area",
        profileNuevaContraseña: "Nueva contraseña",
        // user Tables
        tableName: "Nombre",
        tableEmail: "Correo",
        tableArea: "Area",
        tableRole: "Rol",
        tablePermisos:"Permisos",
        tableFecha: "Fecha de ingreso",
        tableAccesos: "Ultimo acceso",
        tableEstado: "Estado",
        tableAcciones:"Acciones",
        tableDireccion:"Direccion",
        tableActive:"Activo",
        tableInactive:"Inactivo",
        

        // Tableros 

        botonTablero: "Ver Tablero",

        // User Form

        userFormName: "Nombre",
        userFormLastName: "Apellido",
        userFormEmail: "Correo",
        userFormPhone: "Teléfono",
        userFormPassword: "Contraseña",
        userFormRecuperate: "Cambiar contraseña",
        userFormBotonSave: "Guardar",
        userFormBotonEditar: "Editar",
        userFormArea: "Area",
        userFormRol: "Rol",
        userFormCompania: "Empresa",
        userFormFecha: "Fecha de ingreso",
        userFormAccesos: "Ultimo acceso",
        userFormEstado: "Estado",
        userFormAcciones:"Acciones",
        userFormPermisos:"Permisos",
        userFormCrear:"Crear usuario",
        userFormEditar:"Editar usuario",
        userFormBootonClose:"Cerrar",
        userFormProyecto: "Tablero",

        // chat 

        chatPlaceholder: "Escribe un mensaje",

        // modalBoardas

        modalBoardasName: "Nombre",
        modalBoardasDescription: "Descripción",
        modalBoardasUrl: "Url",
        modalBoardasBoton: "Crear",
        modalBoardasBotonClose: "Cerrar",
        modalBoardasBotonEdit: "Editar",
        modalBoardasEmpresa: "Usuarios para Autorizar",
        modalBoardasEmpresaAsignada: "Proyecto",
        modalBoardasUsuarios: "Usuarios",
        modalBoardasUsuariosAsignados: "Todos los usuarios",
        modalBoardsCrear: "Crear tablero",
        modalBoardsEditar: "Editar tablero",
        modalBoardsDataLabelsName: "Nombre del campo", 
        modalBoardsDataLabelsType: "Tipo de elemeto",
        modalBoardsDataLabelsValues: "Valores",


        // insing

        insingTitle: "Titulo",
        insingDescription: "Descripción",
        insingAcciones: "Acciones",
        insingCrear: "Crear insing",
        insingEditar: "Editar insing",
        insingCreateAt: "Fecha de creación",

        modalCompaniesTitleCreate : "Crear empresa / proyecto",
        modalCompaniesTitleEdit: "Editar empresa/ proyecto",
        modalTickestTitleEdit: "Editar tickest",
        modalCompaniesProject: "Proyecto",

        modalCrear: "Crear ",
        modalUpdate: "Actualizar ",
        companyTypeOne: "Empresa",
        companyTypeTwo: "Proyecto",
        companyTypeThree: "Ninguno",
        companyType: "Tipo",
        surveyTitle:"Ayudanos a personalizar tu experiencia",

        linksAdminsUrl:"Url original",
        linksAdminsUrlDestino:"Url destino",
        linksAdminsDesacriptions:"Descripcion",
        searchPrice: "Precio",
        searchLocal:"Tienda",

        dataVikuaTitle:"Titulo",
        tickestTitle:"Titulo",
        tickestDescription: "Descripcion",

        modalTicketTitle:"Crear Ticket",
        modalTicketDescription:"Ingresar Observacion",
        ayuda: "Ayuda"
        
    },
    "en": {
        loginTitleForm: "Welcome",
        loginVerificateForm: "Enter the verification code",
        loginEmail: "Email",
        loginPlaceholderEmail: "Enter your email",
        loginPassword: "Password",
        loginPlaceholderPassword: "Enter your password",
        loginRecuperatePassword: "I forgot my password",
        loginButtonSendLongin: "Sing in",
        loginButtonPrimiun: "Vikua premiun",
        loginSubtutleFrom: "You do not have an account?",
        loginCreatePassword: "Sign up",
        authTitleOne: "The best, life",
        authTitleTwo: "All your data",
        authTitleThree: "in one single place",

        //main
        sidebarServices: "Services",
        sidebarMisTableros: "My boards",
        sidebarTablerosVikua: "Vikua boards",
        profile: "Profile",
        mistablerosTitle: "Boards",
        sidebarUsers: "Users",
        sidebarInsights: "Insights",
        sidebarDataPlayer:"Data Player",
        sidebarData:"Data",
        sidebarMydata:"My data",
        sidebarDatavikua:"Data vikua",
        sidebarMiembros:"Members",
        sidebarEmpresa:"Companies",
        sidebarConfig:"Configurations",
        sidebarAdministrator:"Administrators",
        sidebarCloseSession:"Close session",
        sidebarSearch: "Search",
        sidebarTickets:"Tickets",
        sidebarReports:"Reports",

        // perfil
        profileName: "Name",
        profileLastName: "LastName",
        profileEmail: "Email",
        profilePhone: "Phone",
        profilePassword: "Into you Password",
        profileRecuperate: "Change password",
        profileBoton: "Save",
        profileEditar: "Edit",
        profileDni: "DNI",
        profileArea: "Area",
        profileNuevaContraseña: "New password",

        // user Tables
        tableName: "Name",
        tableEmail: "Email",
        tableArea: "Area",
        tableRole: "Role",
        tablePermisos:"Permissions",
        tableFecha: "Entry Date",
        tableAccesos: "Last access",
        tableEstado: "State",
        tableAcciones:"Actions",
        tableDireccion:"Address",
        tableActive:"Active",
        tableInactive:"Inactive",

        // Tableros 

        botonTablero: "View Dashboard",

        // User Form
        userFormName: "Name",
        userFormLastName: "LastName",
        userFormEmail: "Email",
        userFormPhone: "Phone",
        userFormPassword: "Password",
        userFormRecuperate: "I forgot my password",
        userFormBotonSave: "Save",
        userFormBotonEditar: "Edit",
        userFormArea: "Area",
        userFormRol: "Role",
        userFormCompania: "Company",
        userFormFecha: "Entry Date",
        userFormAccesos: "Last access",
        userFormEstado: "State",
        userFormAcciones:"Actions",
        userFormPermisos:"Permissions",
        userFormCrear:"Create",
        userFormEditar:"Edit",
        userFormBootonClose:"Close",
        userFormProyecto: "Board",

        // chat

        chatPlaceholder: "Write a message",


        // modalBoardas

        modalBoardasName: "Name",
        modalBoardasDescription: "Description",
        modalBoardasUrl: "Url",
        modalBoardasBoton: "Create",
        modalBoardasBotonClose: "Close",
        modalBoardasBotonEdit: "Edit",
        modalBoardasEmpresa: "Users for Authorized ",
        modalBoardasEmpresaAsignada: "Assigned company",
        modalBoardasUsuarios: "Users",
        modalBoardasUsuariosAsignados: "All users",
        modalBoardsCrear: "Create board",
        modalBoardsEditar: "Edit board",
        modalBoardsDataLabelsName: "Label", 
        modalBoardsDataLabelsType: "Type",
        modalBoardsDataLabelsValues: "Values",

        // insing

        insingTitle: "Title",
        insingDescription: "Description",
        insingAcciones: "Actions",
        insingCrear: "Create insing",
        insingEditar: "Edit insing",
        insingCreateAt: "Create at",

        modalCompaniesTitleCreate : "Create company / project",
        modalCompaniesTitleEdit: "Edit company / project",
        modalTickestTitleEdit: "Edit tickest",
        modalCompaniesProject: "Project",

        modalCrear: "Create ",
        modalUpdate: "Update ",
        companyTypeOne: "Company",
        companyTypeTwo: "Project",
        companyTypeThree: "None",
        companyType: "Type",
        surveyTitle:"Your experience is important to us! Help us to know you better",
        linksAdminsUrl:"Url",
        linksAdminsUrlDestino:"Url finally",
        linksAdminsDesacriptions:"Description",

        searchPrice: "Price",
        searchLocal:"Store",

        dataVikuaTitle:"Title",
        tickestTitle:"Title",
        tickestDescription: "Description",
        modalTicketTitle:"Create Ticket",
        modalTicketDescription:"Ingresar Observacion",
        ayuda: "Help"
       
    }
}   